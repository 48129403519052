import React from 'react'

import { useAuth } from '@azos/account'
import analytics from '@azos/analytics'
import { ClickPolicyCoverageDetailsType } from '@azos/analytics/src/domain/models'
import { useMediaQuery } from '@azos/shared'
import { Text } from '@azos/shared/src/components/v2'
import { CoveragesCode } from '@azos/shared/src/lib/coverages'
import {
  DashboardSliderCardsResponse,
  DashboardSliderCardsType,
  WizardList,
} from '@data/models'
import {
  ListNewsBannersResponse,
  PoliciesResponse,
  ProposalsResponse,
} from '@domain/models'
import { ListProductCardsUseCase } from '@domain/usecases'
import { MENU } from '@main/config/menu'
import { makeListProductCardsService } from '@main/factories/services'
import { PolicyCoverageDetails } from '@presentation/components/molecules/Coverages'
import { ImportantInfos } from '@presentation/components/molecules/ImportantInfos'
import { ModalHomeWarning } from '@presentation/components/molecules/ModalHomeWarning'
import { NewsBanner } from '@presentation/components/molecules/NewsBanner'
import { PoliciesList } from '@presentation/components/molecules/Policies'
import { ProposalsList } from '@presentation/components/molecules/Proposals'
import { ResponsibleBrokerCard } from '@presentation/components/molecules/ResponsibleBrokerCard'
import TemplateManager from '@presentation/components/templates/TemplateManager'
import { useBanners } from '@presentation/providers/BannersProvider'
import { useMyPolicies } from '@presentation/providers/MyPoliciesProvider'
import { useOnboardingWizard } from '@presentation/providers/OnboardingWizardProvider'
import { usePolicyReadjustmentProvider } from '@presentation/providers/PolicyReadjustmentProvider'

import { CoveragesList } from './components'
import { HeaderRoot, Root } from './InsuredHome.styles'

type HomeProps = {
  proposals: ProposalsResponse[]
  policies: PoliciesResponse[]
  wizardList: WizardList | null
  newsBanner: ListNewsBannersResponse[]
  importantInfoBanners: DashboardSliderCardsResponse[]
}

const listProductCardsService = makeListProductCardsService()

const InsuredHome: React.FC<any> = ({
  proposals,
  policies: policiesData,
  wizardList: wizardData,
  newsBanner,
  importantInfoBanners,
}) => {
  const { policies, setPolicies } = useMyPolicies()
  const { setWizard } = useOnboardingWizard()
  const { setBanners } = useBanners()
  const { isMobile } = useMediaQuery()
  const { user } = useAuth()
  const { isLoading, isPolicyReadjustmentActive } =
    usePolicyReadjustmentProvider()

  const [selectedCode, setSelectedCode] = React.useState<CoveragesCode | null>(
    null,
  )
  const [isCoverageDetailsOpen, setIsCoverageDetailsOpen] =
    React.useState(false)
  const [isDG10DetailsOpen, setIsDG10DetailsOpen] = React.useState(false)

  const [productCards, setProductCards] =
    React.useState<ListProductCardsUseCase.Response | null>(null)

  const fetchListPolicyCards = React.useCallback(() => {
    const isB2B = user?.isBroker || user?.isInsuredByBroker

    if (user && !isB2B) {
      listProductCardsService
        .execute()
        .then(response => setProductCards(response))
    }
  }, [user])

  React.useEffect(() => {
    if (!isLoading) {
      let tempBanners = importantInfoBanners

      if (!isPolicyReadjustmentActive) {
        tempBanners = importantInfoBanners.filter(
          banner =>
            banner.type !== DashboardSliderCardsType.READJUSTMENT_POLICY,
        )
      }

      setBanners(tempBanners)
    }
  }, [importantInfoBanners, isLoading, isPolicyReadjustmentActive, setBanners])

  React.useEffect(() => {
    setPolicies(policiesData)
  }, [policiesData, setPolicies])

  React.useEffect(() => {
    if (wizardData) {
      setWizard(wizardData)
    }
  }, [wizardData, setWizard])

  React.useEffect(() => {
    fetchListPolicyCards()
  }, [fetchListPolicyCards])

  const handleCoverageClick = (code: CoveragesCode, label: string) => {
    setSelectedCode(code)
    analytics.dashboard.policy.clickCoverageDetails.execute({
      type: ClickPolicyCoverageDetailsType.HOME,
      coverage: label,
    })
    setIsCoverageDetailsOpen(true)
  }

  return (
    <>
      <TemplateManager
        headerProps={{ user }}
        navigationProps={{ value: MENU.INICIO }}
        isHomepage
        headerContent={
          <HeaderRoot>
            <ImportantInfos />
            <NewsBanner itens={newsBanner} />
          </HeaderRoot>
        }
      >
        <Root>
          {productCards && (
            <CoveragesList
              coverages={productCards}
              onClickCoverage={handleCoverageClick}
            />
          )}

          {user?.brokerUser && isMobile && (
            <div className="home__responsible-broker-content">
              <Text variant="body2" className="home__broker-header">
                Fale com o seu <strong>corretor(a)</strong>
              </Text>
              <ResponsibleBrokerCard />
            </div>
          )}
          <div className="home__list-group">
            <PoliciesList policies={policies} max={1} showHeader />
            <ProposalsList proposals={proposals} max={1} showHeader />
          </div>
        </Root>
      </TemplateManager>

      <PolicyCoverageDetails
        code={selectedCode}
        open={isCoverageDetailsOpen}
        onClose={() => setIsCoverageDetailsOpen(false)}
        onRedirect={() => setIsDG10DetailsOpen(true)}
      />

      <PolicyCoverageDetails
        code={CoveragesCode.COD_0005_DOENCAS_GRAVES_10}
        open={isDG10DetailsOpen}
        onClose={() => setIsDG10DetailsOpen(false)}
      />

      <ModalHomeWarning />
    </>
  )
}

export default InsuredHome
